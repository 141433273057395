import React from "react";
import classNames from "classnames";
import { useBootstrapPrefix } from "react-bootstrap/esm/ThemeProvider";
import BootstrapSizeContainer from "../layout/BootstrapSizeContainer";
import { useLocationEffect } from "../../libs/hooksLib";

export default function TabsNav({bsPrefixGroup, bsPrefixItem, stateNav = false, activeKey = 0, setActiveKey = null, items, className, variant = "secondary", horizontal = true, action = true}){

    const location = useLocationEffect();
    bsPrefixGroup = useBootstrapPrefix(bsPrefixGroup, 'tab-group');
    bsPrefixItem = useBootstrapPrefix(bsPrefixItem, 'tab-group-item');
    let horizontalVariant;
    if (horizontal) {
      horizontalVariant = horizontal === true ? 'horizontal' : `horizontal-${horizontal}`;
    }

    if(stateNav){
        return(
            <div className={classNames(className, bsPrefixGroup, variant && `${bsPrefixGroup}-${variant}`, horizontalVariant && `${bsPrefixGroup}-${horizontalVariant}`, "justify-content-center")}>
                {items.map((item, index) => (<button key={index} onClick={() => {setActiveKey(index)}} className={classNames(className, bsPrefixItem, activeKey === index && 'active', variant && `${bsPrefixItem}-${variant}`, action && `${bsPrefixItem}-action`, "text-center")}>
                    {item.iconCmp}<BootstrapSizeContainer size="sm">{item.text}</BootstrapSizeContainer>
                </button>))}
            </div>
        );
    }else{
        return(
            <div className={classNames(className, bsPrefixGroup, variant && `${bsPrefixGroup}-${variant}`, horizontalVariant && `${bsPrefixGroup}-${horizontalVariant}`, "justify-content-center")}>
                {items.map((item, index) => (<a key={index} href={item.url} onClick={item.onClick} className={classNames(className, bsPrefixItem, item.url === location.pathname && 'active', variant && `${bsPrefixItem}-${variant}`, action && `${bsPrefixItem}-action`, "text-center")}>
                    {item.iconCmp}<BootstrapSizeContainer size="sm">{item.text}</BootstrapSizeContainer>
                </a>))}
            </div>
        );
    }
}