import React from "react";
import PricingContainer from "../containers/pricing/PricingContainer";
import Main from "../static-components/html/Main";

export default function PricingPage(){
    return (
        <Main title="Pricing">
            <PricingContainer />
        </Main>
    );
};