import React, { useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import SignInButtons from "../../components/buttons/SignInButtons";
import Jumbotron from "../../components/layout/Jumbotron";
import { Calendar3, Calendar3Event } from "react-bootstrap-icons";
import TabsNav from "../../components/buttons/TabsNav";

export default function PricingContainer() {

    const [pricingIndex, setPricingIndex] = useState(0);

  function renderLander() {
    return (
      <>
        <Container>
          <Row className="mb-5">
            <Col>
              <Jumbotron className="bg-white pb-5">
                  <Row className="mb-3">
                    <Col xs={12}>
                      <h5 className="text-center text-primary"><strong>Pricing</strong></h5>
                    </Col>
                  </Row>
                  <Row className="justify-content-center mb-5">
                    <Col sm={10} md={8} lg={6}>
                      <h1 className="text-center">Free to try. <br/>Pay as you scale.</h1>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col sm={6}>
                      <SignInButtons signInText={null} signUpText={"Get started for free"} />
                    </Col>
                  </Row>
              </Jumbotron>
            </Col>
          </Row>
        </Container>
        <div className="p-2 bg-light">
            <Container>
                <Row className="my-3">
                    <Col>
                        <p>Our plans are simple. Try the fully featured free plan, or if you need to scale candidates or recruit multiple roles choose from several paid plans. Users are always unlimited.</p>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col>
                        <TabsNav stateNav variant="navy" activeKey={pricingIndex} setActiveKey={setPricingIndex} items={
                            [
                                {text: "Pay annually", iconCmp: <Calendar3 className="me-3" />},
                                {text: "Pay monthly", iconCmp: <Calendar3Event className="me-3" />}
                            ]
                        } />
                    </Col>
                </Row>
                <Row className="my-2 pt-3">
                    <Col sm={6} md={3}>
                        <Card className="rounded-5 mb-3">
                            <Card.Header className="border rounded-top-5 border-primary bg-white text-primary text-center fw-bold">Free</Card.Header>
                            <Card.Body>
                                <Card.Text className="text-center">
                                    1 active job<br/>
                                    5 applicants per month<br/>
                                    Unlimited users
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer className="rounded-bottom-5 bg-white text-primary text-center fw-bold">Free</Card.Footer>
                        </Card>
                    </Col>
                    <Col sm={6} md={3}>
                        <Card className="rounded-5 mb-3">
                            <Card.Header className="border rounded-top-5 border-complement bg-white text-complement text-center fw-bold">Starter</Card.Header>
                            <Card.Body>
                                <Card.Text className="text-center">
                                    3 active jobs<br/>
                                    100 applicants per month<br/>
                                    Unlimited users
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer className="rounded-bottom-5 bg-white text-complement text-center fw-bold">{pricingIndex === 0 ? "$24" : "$29"} / month</Card.Footer>
                        </Card>
                    </Col>
                    <Col sm={6} md={3}>
                        <Card className="rounded-5 mb-3">
                            <Card.Header className="border rounded-top-5 bg-complement text-white text-center fw-bold">Standard</Card.Header>
                            <Card.Body>
                                <Card.Text className="text-center">
                                    8 active jobs<br/>
                                    200 applicants per month<br/>
                                    Unlimited users
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer className="rounded-bottom-5 bg-complement text-white text-center fw-bold">{pricingIndex === 0 ? "$49" : "$59"} / month</Card.Footer>
                        </Card>
                    </Col>
                    <Col sm={6} md={3}>
                        <Card className="rounded-5 mb-3">
                            <Card.Header className="border rounded-top-5 border-secondary bg-white text-secondary text-center fw-bold">Premium</Card.Header>
                            <Card.Body>
                                <Card.Text className="text-center">
                                    20 active jobs<br/>
                                    200 applicants per month<br/>
                                    Unlimited users
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer className="rounded-bottom-5 bg-white text-secondary text-center fw-bold">{pricingIndex === 0 ? "$99" : "$119"} / month</Card.Footer>
                        </Card>
                    </Col>
                </Row>
                <Row className="mb-3 text-center">
                    <Col>Need a bit more? <a href="mailto:support@pitchli.com?subject=Pitchli%20account%20plans">Contact us</a></Col>
                </Row>
            </Container>
        </div>
        <Container>
            <Row className="justify-content-center my-5">
                <Col sm={6}>
                    <SignInButtons signInText={null} signUpText={"Get started for free"} />
                </Col>
            </Row>
        </Container>
        <div className="bg-light py-3">
            <Container>
                <Row className="my-3">
                    <Col>
                        <h2>Pricing FAQ</h2>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={6}>
                        <Card className="rounded-5 mb-3">
                            <Card.Header className="border rounded-top-5 border-navy bg-white text-navy text-center fw-bold">
                                What does applicants per month mean?
                            </Card.Header>
                            <Card.Body>
                                <Card.Text className="text-center">
                                    Each person who responds to your interview request counts towards your monthly applicant limit. Once this limit is reached, you will need to upgrade your plan to view any further video responses.
                                    <br/><br/>
                                    If you've reached your plan limit, people are still able to respond. At the start of the next calendar month your applicant count will reset to zero.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={12} md={6}>
                        <Card className="rounded-5 mb-3">
                            <Card.Header className="border rounded-top-5 border-navy bg-white text-navy text-center fw-bold">
                                What does active jobs mean?
                            </Card.Header>
                            <Card.Body>
                                <Card.Text className="text-center">
                                    Each job currently open for applications counts towards your active jobs limit. You can have unlimited draft jobs, or expired jobs. Expiring a job will free up 1 allocation against your active jobs limit.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
      </>
    );
  }

  return (
    <div className="Home">
      {renderLander()}
    </div>
  );
}